import React, { Component } from 'react';
import './App.css';
import 'react-tooltip/dist/react-tooltip.css'
import DemoCreatorWidget from './pages/DemoCreatorWidget'
import StatusChecker from './pages/StatusChecker'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


class App extends Component {
  render() {
    return (
      <div className="App flex justify-center max-h-[540px] overflow-y-auto">
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<DemoCreatorWidget />} />
            <Route path="/status-checker/:email" element={<StatusChecker />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
